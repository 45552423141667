/**
 * @constant
 * Array of suported locales
 */

export const supportedLocales = [
  "en",
  "fr",
  "it",
  "de",
  "es",
  "pt",
  "ja",
  "ko",
  "ru",
  "tr",
  "ar",
  "he",
  "nl",
  "zh-Hans",
  "zh-Hant",
];


/**
 * @constant
 * Array of Pluton RMC's
 */

export const plutonRmc = [
  "M7939G1A0NRU-0001",
  "M7939G1A0NRU-0002"
];
